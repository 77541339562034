import * as React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import { BlogPost } from '@schema';

import { media } from '../../styles/mixins';
import { formatDateToUsFormat } from '../blog/utils';
import { Breadcrumbs } from '../breadcrumbs';
import { SkImage } from '../shared/image';
import { RemixIcon } from '../shared/remix-icon';
import { Typography } from '../shared/typography';

interface HeroBlogProps {
	post: BlogPost;
}

export const HeroBlog: React.FC<HeroBlogProps> = ({ post }) => {
	const { createdAt, description, heroSectionImage, title, minutesToRead, blogPostTags, meta } =
		post;

	const formattedData = formatDateToUsFormat(createdAt);

	return (
		<HeroWrap>
			<Breadcrumbs slug={'/blog/'} />
			<ContentWrap>
				<Header>Latest Post</Header>
				<Link to={meta.url}>
					<PostCard>
						<CardImg>
							<SkImage
								url={heroSectionImage?.data?.attributes?.url}
								alt={heroSectionImage?.data?.attributes?.alternativeText || 'post hero image'}
							/>
						</CardImg>
						<CardContent>
							<CardHeader>
								{blogPostTags?.data && (
									<Tags>
										{blogPostTags.data.map((tag) => (
											<Tag key={tag.attributes.tag}>
												<Typography data-style="tag">{tag.attributes.tag}</Typography>
											</Tag>
										))}
									</Tags>
								)}
								<Title>{title}</Title>
								<Typography data-style="excerpt" as="p">
									{description}
								</Typography>
							</CardHeader>
							<CardFooter>
								<Typography data-style="features-item">{formattedData}</Typography>
								<Typography data-style="features-item">
									<FeaturesRemixIcon className="ri-time-line" />
									{minutesToRead} min
								</Typography>
							</CardFooter>
						</CardContent>
					</PostCard>
				</Link>
			</ContentWrap>
		</HeroWrap>
	);
};

const Header = styled.h2`
	line-height: 24px;
	margin-bottom: 16px;
	${css`
		font-size: ${(p) => p.theme.typography.sizes.h4};
	`}
	font-weight: 600;
	color: ${(p) => p.theme.colors.headerText};
	${media.md`
		line-height: 40px;
		${css`
			font-size: ${(p) => p.theme.typography.sizes.t6};
		`}
	`}
	${media.xl`
		margin-bottom: 24px;
		line-height: 48px;
		${css`
			font-size: ${(p) => p.theme.typography.sizes.h3};
		`}
	`}
`;

const Title = styled.h3`
	line-height: 24px;
	${css`
		font-size: ${(p) => p.theme.typography.sizes.t4};
	`}
	font-weight: 600;
	color: ${(p) => p.theme.colors.headerText};
	${media.md`
		line-height: 28px;
		${css`
			font-size: ${(p) => p.theme.typography.sizes.t5};
		`}
	`}
	${media.xl`
		line-height: 32px;
		${css`
			font-size: ${(p) => p.theme.typography.sizes.h4};
		`}
	`}
`;

const HeroWrap = styled.div`
	& [data-style='title'] {
		margin-bottom: 16px;
		line-height: 32px;
		color: ${(p) => p.theme.colors.gray5};
	}

	a {
		color: initial;
	}

	padding: 0 16px;

	${media.md`
		& [data-style='title'] {
      		line-height: 40px;
    	}
		padding: 0 24px;
  `}

	${media.xl`
		padding: 0 80px;
		${(p) => css`
			& [data-style='title'] {
				margin-bottom: 24px;
				font-size: ${p.theme.typography.sizes.h3};
				line-height: 48px;
			}
		`}
  `}

  	${media.xxl`
		padding: 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Tags = styled.ul`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 16px;
`;

const Tag = styled.li``;

const ContentWrap = styled.div`
	margin: 16px 0 64px;
	> a {
		width: 100%;
	}

	${media.md`
    margin: 32px 0 84px;
  `}

	${media.xl`
    	margin: 100px 0 160px;
  	`}
`;

const PostCard = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;

	${media.lg`
		display: grid;
		grid-template-columns: 2fr 1fr;
		width: 100%;
	`}
`;

const CardImg = styled.div`
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		min-height: 240px;

		${media.md`
      min-height: 440px;
    `}

		${media.xxl`
      min-height: 600px;
      max-width: 1048px;
    `}
	}
`;

const CardContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin: 0 24px;

	${media.lg`
    justify-content: space-between;
    margin: 24px 0;
	`}
`;

const CardHeader = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;

	& [data-style='tag'],
	& [data-style='excerpt'] {
		color: ${(p) => p.theme.colors.gray6};
	}

	& [data-style='tag'] {
		text-transform: uppercase;
		font-weight: 500;
		line-height: 16px;
	}

	& [data-element='html'] [data-style='name'] {
		color: ${(p) => p.theme.colors.gray5};
		font-size: ${(p) => p.theme.typography.sizes.t4};
	}

	& [data-style='excerpt'] {
		font-size: ${(p) => p.theme.typography.sizes.t2};
		line-height: 16px;
	}

	${media.md`
    ${(p) => css`
			& [data-style='tag'] {
				font-size: ${p.theme.typography.sizes.t2};
				line-height: 20px;
			}

			& [data-element='html'] [data-style='name'] {
				font-size: ${p.theme.typography.sizes.h5};
				font-weight: 600;
				line-height: 28px;
			}

			& [data-style='excerpt'] {
				font-size: ${p.theme.typography.sizes.t3};
				line-height: 22px;
			}
		`}
  `}

	${media.lg`
    ${(p) => css`
			[data-element='html'] [data-style='name'] {
				font-size: ${p.theme.typography.sizes.h4};
				line-height: 32px;
			}
		`}
  `}
`;

const CardFooter = styled.div`
	display: flex;
	justify-content: space-between;

	& [data-style='features-item'] {
		color: ${(p) => p.theme.colors.gray6};
		display: flex;
		gap: 5px;
		line-height: 16px;
	}

	${media.md`
    ${(p) => css`
			& [data-style='features-item'] {
				font-size: ${p.theme.typography.sizes.t2};
				line-height: 20px;
			}
		`}
  `}
`;

const FeaturesRemixIcon = styled((p) => <RemixIcon {...p} />)`
	color: ${(p) => p.theme.colors.gray6};
	font-size: 16px;
`;
