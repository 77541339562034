import * as React from 'react';
import styled from 'styled-components';

import {
	BlogPost,
	BlogPostTag,
	CookieBannerEntityResponse,
	FooterEntityResponse,
	HeaderEntityResponse,
	MenuEntityResponse,
} from '@schema';

import { ArticlesSection } from '../../components/blog/blog-page/blog-articles';
import { BlogFeatured } from '../../components/blog/blog-page/blog-featured/blog-featured';
import { BlogMostPopular } from '../../components/blog/blog-page/blog-most-popular/blog-most-popular';
import { BlogSeo } from '../../components/blog/blog-page/blog-seo';
import { HeroBlog } from '../../components/heroBlog';
import MainLayout from '../../layouts/main-layout';
import { media } from '../../styles/mixins';

export interface PostData {
	id: number;
	attributes: BlogPost;
}

export interface TagData {
	id: number;
	attributes: BlogPostTag;
}

interface BlogPageProps {
	pageContext: {
		menu?: MenuEntityResponse;
		header?: HeaderEntityResponse;
		footer?: FooterEntityResponse;
		cookieBanner?: CookieBannerEntityResponse;
		blog?: PostData[];
		tags?: TagData[];
	};
}

const BlogPage: React.FC<BlogPageProps> = (props: BlogPageProps) => {
	const { menu, header, footer, cookieBanner, blog, tags } = props.pageContext;
	const latestPost = blog.sort(
		(a, b) => Number(new Date(b.attributes.createdAt)) - Number(new Date(a.attributes.createdAt)),
	)[0].attributes;
	const featuredPosts = blog.filter((item) => item.attributes.types?.includes('featured'));
	const popularPosts = blog.filter((item) => item.attributes.types?.includes('popular'));
	return (
		<MainLayout
			menu={menu}
			header={header}
			footer={footer}
			cookieBanner={cookieBanner}
			overflow="hidden"
			theme="/blog"
		>
			<BlogSeo />
			<HeroBlog post={latestPost} />
			<SecondaryWrap>
				<Container>
					<BlogFeatured posts={featuredPosts} />
				</Container>
			</SecondaryWrap>

			<Container>
				<ArticlesSection posts={blog} tags={tags} />
			</Container>

			<SecondaryWrap>
				<Container>
					<BlogMostPopular posts={popularPosts} />
				</Container>
			</SecondaryWrap>
		</MainLayout>
	);
};

const Container = styled.div`
	padding-left: var(--section-sm-horizontal-padding);
	padding-right: var(--section-sm-horizontal-padding);

	${media.md`
    padding-left: var(--section-md-horizontal-padding);
    padding-right: var(--section-md-horizontal-padding);
  `}

	${media.xl`
    padding-left: var(--section-xl-horizontal-padding);
    padding-right: var(--section-xl-horizontal-padding);
		margin: 0 auto;
  `}

  ${media.xxl`
    padding-left: var(--section-xxl-horizontal-padding);
    padding-right: var(--section-xxl-horizontal-padding);

		max-width: 1460px;
  `}
`;

const SecondaryWrap = styled.section`
	background: ${(p) => p.theme.colors.backgroundGray};
	padding: 80px 0;
	position: relative;

	${media.md`
		padding: 120px 0;
	`}

	${media.xl`
		padding: 160px 0;
	`}
`;

export default BlogPage;
